/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { colors } from "../../gatsby-plugin-theme-ui"

export const coloredLine = ({ center = false, alignTop = false }) => ({
  "&:after": {
    content: '""',
    height: "3px",
    width: "111px",
    position: "absolute",
    top: alignTop ? 0 : "100%",
    left: center ? "calc(50% - 111px / 2)" : 0,
    backgroundImage: `linear-gradient(to right, ${colors.pink} 38px, transparent 38px, transparent 39px, ${colors.blue} 39px, ${colors.blue} 100%)`,
  },
})

const SectionTitle = ({ center, ...props }) => (
  <Themed.h2
    sx={{
      fontSize: "1.2rem",
      lineHeight: 1.25,
      textTransform: "uppercase",
      position: "relative",
      pb: "1rem",
      mb: ["4rem", "6rem", "8rem"],
      textAlign: center ? "center" : null,
      ...coloredLine({ center }),
    }}
    {...props}
  />
)

export default SectionTitle
