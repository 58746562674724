/** @jsx jsx */
import { jsx } from "theme-ui"
import { gridStyles } from "../grid/grid"

const Panel = props => (
  <section
    sx={{
      ...gridStyles,
      img: {
        maxWidth: "100%",
        verticalAlign: "middle",
      },
    }}
    {...props}
  />
)

export default Panel
