/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { MdMenu, MdClose } from "react-icons/md"
import { Button } from "../button/button"
import Link from "../link/link"
import ModalContactForm from "../modal-contact-form/modal-contact-form"
import { UnbulletedList } from "../flat-lists/flat-lists"
import LogoHorizontal from "../../images/moth-flame-logo-horizontal.png"
import { headerHeight } from "../../utils/constants"
import { siteMaxWidth } from "../../gatsby-plugin-theme-ui"

const SiteHeader = ({ bg }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const closeMenu = () => {
    document.querySelector("body").style.overflow = null
    setMenuOpen(false)
  }
  const data = useStaticQuery(graphql`
    query {
      libraries: allPrismicLearningLibrary(
        sort: {fields: data___sort_order, order: ASC}
        filter: {uid: {ne: "ready"}}
      ) {
        nodes {
          data {
            title {
              text
            }
            navigation_title
          }
          uid
        }
      }
    }
  `)
  return (
    <div sx={{ maxWidth: `${siteMaxWidth}px`, mx: "auto", bg }}>
      <div
        sx={{
          display: [null, "flex"],
          justifyContent: "space-between",
          "ul a": {
            display: "block",
            px: ["3.8rem", "1rem", "2rem"],
            py: "1.6rem",
            position: "relative",
            fontSize: ["1.8rem", "1.4rem"],
            lineHeight: [1, 1.25],
            fontWeight: "bold",
            textDecoration: "none",
            color: "text",
            "&:before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: "10px",
              right: "10px",
              height: "1px",
              bg: "lightGray",
            },
            "&:hover": {
              bg: "blue",
              color: "#fff",
              em: {
                color: ["#fff", "blue"],
              },
            },
            "&:active": {
              bg: "blueAction",
            },
            em: {
              fontStyle: "normal",
              color: "blue",
              fontWeight: 600,
            },
          },
          img: {
            verticalAlign: "middle",
          },
          small: {
            fontSize: "1.2rem",
            lineHeight: 1.5,
            fontWeight: 300,
          },
        }}
      >
        <div
          sx={{
            borderBottom: ["solid 1px", 0],
            borderColor: "lightGray",
            lineHeight: 1,
            flexShrink: 0,
            display: "flex",
            justifyContent: "space-between",
            position: ["fixed", "unset"],
            top: 0,
            zIndex: 10000,
            width: ["100%", "unset"],
            bg: ["#fff", "unset"],
          }}
        >
          <Link
            to="/"
            sx={{
              display: "inline-flex",
              px: ["1rem", "2rem", "3.2rem"],
              py: ["1.2rem", "1.6rem", "1.3rem"],
              textDecoration: "none",
              color: "text",
              span: {
                display: "block",
                backgroundImage: `url(${LogoHorizontal})`,
                backgroundSize: ["24.6rem 3.7rem", null, "37.3rem 5.6rem"],
                width: ["24.6rem", null, "37.3rem"],
                height: ["3.7rem", null, "5.6rem"],
                textIndent: "-9999rem",
              },
            }}
          >
            <span>Moth+Flame VR</span>
          </Link>
          <button
            sx={{
              bg: "transparent",
              border: 0,
              p: 0,
              mr: "2rem",
              display: [null, "none"],
            }}
            onClick={() => {
              const overflow = menuOpen ? null : "hidden"
              document.querySelector("body").style.overflow = overflow
              setMenuOpen(!menuOpen)
            }}
            aria-label={menuOpen ? "Close menu" : "Open menu"}
          >
            {menuOpen ? <MdClose size="2.6rem" /> : <MdMenu size="2.6rem" />}
          </button>
        </div>
        <UnbulletedList
          sx={{
            display: [menuOpen ? "flex" : "none", "flex"],
            flexDirection: ["column", "row"],
            position: ["fixed", "unset"],
            zIndex: 1,
            top: `${headerHeight.mobile}px`,
            bg: ["#fff", "unset"],
            overflowY: ["scroll", "unset"],
            height: [`calc(100vh - ${headerHeight.mobile}px)`, "unset"],
            width: ["100%", "unset"],
            "> li": {
              "> a": {
                fontSize: ["1.8rem", "1.2rem", "1.4rem"],
                fontWeight: ["bold", "unset"],
                py: ["1.6rem", "2.8rem", "3rem"],
                "&:hover": {
                  bg: ["blue", "unset"],
                  color: ["#fff", "blue"],
                  "&:before": {
                    display: "none",
                  },
                },
                "&:active": {
                  bg: ["blue", "unset"],
                  color: ["#fff", "blueAction"],
                },
                "&:before": {
                  display: [null, "none"],
                },
                "> span": {
                  display: [null, "none"],
                },
                "> small": {
                  display: ["block", "none"],
                },
              },
              ul: {
                display: [null, "none"],
                position: [null, "absolute"],
                top: "1.5rem",
                zIndex: 1,
                bg: [null, "#fff"],
                boxShadow: [null, "0 3px 6px 0 rgba(0, 0, 0, 0.16)"],
                borderRadius: [null, "6px"],
                pt: [null, "5rem", "6rem"],
                small: {
                  display: "block",
                },
                "a:hover:before": {
                  display: "none",
                },
              },
              "&:not(.button)": {
                order: [2, "unset"],
              },
              "&.button": {
                order: [1, "unset"],
                pl: ["3.8rem", "1.5rem"],
                pr: ["3.8rem", "2.5rem", "3rem"],
                py: ["3.3rem", "unset"],
                position: "relative",
                display: [null, "inline-flex"],
                alignItems: "center",
                button: {
                  width: ["100%", "unset"],
                  height: [null, "3.7rem", "4rem"],
                  fontSize: ["1.8rem", "1.2rem", "1.4rem"],
                },
              },
              "&:hover ul": {
                display: [null, "block"],
              },
              "&.linkedin, &.twitter": {
                display: [null, "none"],
              },
              "&.careers": {
                display: [null, "none", "unset"],
              },
              "&.libraries": {
                ul: {
                  display: "none",
                },
                "&:hover ul": {
                  display: [null, "block"],
                },
                "> a": {
                  zIndex: 2,
                  "&:after": {
                    content: '"+"',
                    position: "static",
                    height: "auto",
                    bg: "unset",
                    display: ["none", "inline"],
                    color: "blue",
                    fontWeight: "bold",
                    ml: "0.5rem",
                  },
                },
              },
            },
          }}
        >
          <li className="libraries">
            <Link to="/learning-libraries" onClick={closeMenu}>
              Learning Catalog
            </Link>
            <UnbulletedList>
              {data.libraries.nodes.map(library => (
                <li key={library.uid}>
                  <Link to={`/learning-libraries/${library.uid}`} onClick={closeMenu}>
                    {library.data.navigation_title ?? library.data.title.text}
                  </Link>
                </li>
              ))}
            </UnbulletedList>
          </li>
          <li>
            <Link to="/learning-libraries/ready/" onClick={closeMenu}>
              Government
            </Link>
          </li>
          <li>
            <Link to="/authoring/" onClick={closeMenu}>
              Authoring
            </Link>
          </li>
          <li>
            <Link to="/pricing/" onClick={closeMenu}>
              Pricing
            </Link>
          </li>
          <li className="button">
            <ModalContactForm trigger={<Button>Connect with us</Button>} />
          </li>
          <li className="linkedin">
            <Link
              to="https://www.linkedin.com/company/moth-flame-vr/"
              target="_blank"
              onClick={closeMenu}
            >
              Moth+Flame LinkedIn
            </Link>
          </li>
          <li className="twitter">
            <Link
              to="https://twitter.com/mothandflamevr"
              target="_blank"
              onClick={closeMenu}
            >
              Moth+Flame Twitter
            </Link>
          </li>
        </UnbulletedList>
      </div>
    </div>
  )
}

export default SiteHeader
