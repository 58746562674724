/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Link from "../link/link"
import { colors } from "../../gatsby-plugin-theme-ui"

export const Button = ({ type, children, color = "blue", ...props }) => {
  const Component = type === "link" ? Link : "button"
  return (
    <Component
      type={type === "link" ? null : type}
      sx={{
        m: 0,
        px: "2.8rem",
        border: 0,
        borderRadius: "2.6rem",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        whiteSpace: "nowrap",
        "&:hover:not(:disabled)": {
          bg: color === "pink" ? "pinkAction" : "blueAction",
        },
        "&:disabled": {
          opacity: 0.2,
        },
        "&:focus:not(:disabled)": {
          outline: 0,
          boxShadow: `0px 0px 0px 5px ${colors.lightGray}`,
        },
        minWidth: "10rem",
        height: "4.8rem",
        fontFamily: "Montserrat",
        fontSize: ["1.2rem", null, "1.4rem"],
        lineHeight: ["1.5rem", null, "1.8rem"],
        fontWeight: "bold",
        // passes color contraster according to https://contrast-ratio.com/#hsla%28100%2C22%25%2C100%25%2C1%29-on-hsl%28216%2C%2082%25%2C%2052%25%29
        bg: color === "pink" ? "hsl(331, 84%, 48%)" : "hsl(216, 82%, 52%)",
        color: "#fff",
        cursor: "pointer",
      }}
      {...props}
    >
      <span>{children}</span>
    </Component>
  )
}

export const ButtonLink = props => (
  <Button
    type="link"
    sx={{
      textDecoration: "none",
      verticalAlign: "top",
      "&:hover": {
        color: "#fff",
      },
    }}
    {...props}
  />
)

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
Button.defaultProps = {
  type: "button",
}
