/** @jsx jsx */
import { jsx, Themed } from "theme-ui"

export const UnbulletedList = props => (
  <Themed.ul
    sx={{
      listStyleType: "none",
      m: 0,
      p: 0,
    }}
    {...props}
  />
)

export const UnorderedList = props => (
  <Themed.ol
    sx={{
      listStyleType: "none",
      m: 0,
      p: 0,
    }}
    {...props}
  />
)
