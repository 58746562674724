export const scrollToRef = (ref, offset = 0) =>
  window.scrollTo({ top: ref.current.offsetTop + offset, behavior: "smooth" })

export const scrollToId = (id, offset = 0) => {
  const element = document.getElementById(id)
  if (element) {
    const top = element.offsetTop + offset
    window.scrollTo({ top, behavior: "smooth" })
  }
}

export const disableScrolling = disable => {
  if (disable) {
    document.querySelector("body").style.overflow = "hidden"
  } else {
    document.querySelector("body").style.overflow = null
  }
}
