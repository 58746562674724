import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Location } from "@reach/router"

export const CampaignCookie = ({ location }) => {
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const campaign = params.get("campaign")
    if (campaign) window.localStorage.setItem("campaign", campaign)
  }, [location.search])

  return null
}

CampaignCookie.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
}

const CampaignCookieWithQuery = props => {
  return (
    <Location>
      {({ location }) => <CampaignCookie {...props} location={location} />}
    </Location>
  )
}

export default CampaignCookieWithQuery
