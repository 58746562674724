/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import React, { useState, useEffect } from "react"
import { Formik, Form, useField, useFormikContext } from "formik"
import * as Yup from "yup"
import { Button } from "../button/button"
import AWSMobile from "../../aws-exports"
import { useModal } from "../../contexts/modal"
import { UnorderedList } from "../flat-lists/flat-lists"
import { coloredLine } from "../section-title/section-title"

const TextInput = ({ label, type, fieldRef, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <li
      sx={{
        mb: "4rem",
        label: {
          display: "block",
          fontWeight: 600,
        },
        "input, textarea": {
          fontFamily: "Montserrat",
          fontSize: "1.6rem",
          lineHeight: [1.5, null, 1.75],
          border: 0,
          borderBottom: "solid 2px",
          borderColor: "lightGray",
          width: "100%",
          py: "0.5rem",
          "&:focus": {
            borderColor: "#9ba0a2",
            outline: 0,
          },
        },
      }}
    >
      <label htmlFor={props.name}>{label}</label>
      {type === "textArea" ? (
        <textarea {...field} {...props} id={props.name} ref={fieldRef} />
      ) : (
        <input
          {...field}
          {...props}
          type={type}
          id={props.name}
          ref={fieldRef}
        />
      )}
      {meta.touched && meta.error ? (
        <div className="error" sx={{ color: "red", mt: "0.5rem" }}>
          {meta.error}
        </div>
      ) : null}
    </li>
  )
}

const AutoSetFields = () => {
  const { setFieldValue } = useFormikContext()
  useEffect(() => {
    setFieldValue("campaign", window.localStorage.getItem("campaign") || "")
  }, [setFieldValue])
  return null
}

const ContactForm = ({ firstFieldRef, product = "" }) => {
  const [loadTime] = useState(Date.now())
  const [formError, setFormError] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const { dismissModal } = useModal() ?? {}
  return (
    <div>
      <h1
        sx={{
          fontSize: "2.6rem",
          lineHeight: 1.5,
          fontWeight: 400,
          mt: 0,
          mb: "6rem",
          textAlign: "center",
          position: "relative",
          pt: "2.4rem",
          span: {
            fontWeight: "bold",
          },
          ...coloredLine({ center: true, alignTop: true }),
        }}
      >
        Contact <span>Moth+Flame</span>
      </h1>
      <Formik
        initialValues={{
          name: "",
          email: "",
          company: "",
          message: "",
          product,
          password: "",
          campaign: "",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Required"),
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          company: Yup.string().required("Required"),
          message: Yup.string(),
          product: Yup.string(),
          campaign: Yup.string(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const submissionTime = Date.now()
          // disable submissions that took less than 3 seconds
          if (submissionTime - loadTime > 3000) {
            const endpoint = AWSMobile.aws_cloud_logic_custom[0].endpoint
            fetch(`${endpoint}/contact`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(values),
            })
              .then(() => {
                setSubmitting(false)
                setSubmitted(true)
              })
              .catch(error => setFormError(error.message))
          }
        }}
      >
        {({ isSubmitting }) => (
          <>
            {submitted && (
              <div
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Themed.p>We will respond within 24 hours. Thanks!</Themed.p>
                {dismissModal && (
                  <div>
                    <Button onClick={dismissModal}>Done</Button>
                  </div>
                )}
              </div>
            )}
            {!submitted && (
              <Form>
                {formError && (
                  <div
                    sx={{
                      backgroundColor: "red",
                      color: "white",
                      padding: "1em",
                      marginBottom: "1em",
                    }}
                  >
                    {formError}
                  </div>
                )}
                <input
                  type="text"
                  name="password"
                  tabIndex="-1"
                  autoComplete="off"
                  sx={{
                    display: "none",
                  }}
                />
                <UnorderedList>
                  <TextInput
                    label="Name"
                    name="name"
                    type="text"
                    fieldRef={firstFieldRef}
                  />
                  <TextInput label="Company" name="company" />
                  <TextInput label="Email" name="email" type="email" />
                  <TextInput label="Message" name="message" type="textArea" />
                  <li
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: "6rem",
                    }}
                  >
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      sx={{ m: "0 auto" }}
                    >
                      Submit
                    </Button>
                  </li>
                </UnorderedList>
                <AutoSetFields />
              </Form>
            )}
          </>
        )}
      </Formik>
    </div>
  )
}

export default ContactForm
