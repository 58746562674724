import React from "react"
import PropTypes from "prop-types"
import { Global } from "@emotion/react"
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import SiteHeader from "../site-header/site-header"
import SiteFooter from "../site-footer/site-footer"
import CampaignCookie from "../campaign-cookie/campaign-cookie"
import { Helmet } from "react-helmet"
if (typeof window !== "undefined") {
  require("@slightlyoff/lite-vimeo")
  require("@justinribeiro/lite-youtube")
  window.chatbaseConfig = {
    chatbotId: "CNj3DzDI3VNXTLGNL9aWA",
  }
}

const Layout = ({ children, headerbg }) => (
  <>
    <Global
      styles={theme => ({
        // https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
        // slope = (maxFontSize - minFontSize) / (maxWidth - minWidth)
        // yAxisIntersection = -minWidth * slope + minFontSize
        // preferredValue = yAxisIntersection[rem] + (slope * 100)[vw]
        ".scaleText": {
          "--fs-slope":
            "calc((var(--max-font-size) - var(--min-font-size)) / (var(--max-width) - var(--min-width)))",
          "--fs-y-axis-intersection":
            "calc(var(--min-width) * -1 * var(--fs-slope) + var(--min-font-size))",
          fontSize:
            "clamp(calc(var(--min-font-size) * 1rem), calc(var(--fs-y-axis-intersection) * 1rem) + calc(var(--fs-slope) * 100 * 1vw), calc(var(--max-font-size) * 1rem))",
        },
        ".scaleHeight": {
          "--h-slope":
            "calc((var(--max-height) - var(--min-height)) / (var(--max-width) - var(--min-width)))",
          "--h-y-axis-intersection":
            "calc(var(--min-width) * -1 * var(--h-slope) + var(--min-height))",
          height:
            "clamp(calc(var(--min-height) * 1rem), calc(var(--h-y-axis-intersection) * 1rem) + calc(var(--h-slope) * 100 * 1vw), calc(var(--max-height) * 1rem))",
        },
        ".scaleMarginBottom": {
          "--mb-slope":
            "calc((var(--max-mb) - var(--min-mb)) / (var(--max-width) - var(--min-width)))",
          "--mb-y-axis-intersection":
            "calc(var(--min-width) * -1 * var(--mb-slope) + var(--min-mb))",
          marginBottom:
            "clamp(calc(var(--min-mb) * 1rem), calc(var(--mb-y-axis-intersection) * 1rem) + calc(var(--mb-slope) * 100 * 1vw), calc(var(--max-mb) * 1rem))",
        },
        ":focus:not(:focus-visible)": {
          outline: "none",
        },
        html: {
          fontSize: "10px",
          // scrollBehavior: "smooth",
        },
      })}
    />
    <SiteHeader bg={headerbg} />
    {children}
    <SiteFooter />
    <CampaignCookie />
    <Helmet>
      <script
        src="https://www.chatbase.co/embed.min.js"
        id="CNj3DzDI3VNXTLGNL9aWA"
        defer>
      </script>
    </Helmet>
  </>
)

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Layout
