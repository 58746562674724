/** @jsx jsx */
import { jsx } from "theme-ui"
import { useRef } from "react"
import Modal from "../modal/modal"
import ContactForm from "../contact-form/contact-form"

const ModalContactForm = ({ trigger, product }) => {
  const initialFocusRef = useRef()
  return (
    <Modal
      trigger={trigger}
      modalContent={
        <ContactForm firstFieldRef={initialFocusRef} product={product} />
      }
      initialFocusRef={initialFocusRef}
    />
  )
}

export default ModalContactForm
