import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { Location } from "@reach/router"

export const buildSocialImage = image => {
  if (!image) return null
  return {
    url: image.images.fallback.src,
    width: image.width,
    height: image.height,
    alt: image.title,
  }
}

export const MetadataContainer = ({
  title,
  description,
  image,
  location,
  siteMetadata,
  isHome,
  noIndex,
}) => {
  const browsertitle = isHome ? title : `${title} – ${siteMetadata.siteTitle}`
  const canonicalUrl =
    siteMetadata.siteUrl + location.pathname.replace(/\/?$/, "/")

  return (
    <Helmet
      title={browsertitle}
      htmlAttributes={{
        lang: "en",
      }}
    >
      {/* General tags */}
      {description && <meta name="description" content={description} />}
      <link rel="canonical" href={canonicalUrl} />

      {/* OpenGraph tags */}
      <meta property="og:site_name" content={siteMetadata.siteTitle} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      {image && image.url && <meta property="og:image" content={siteMetadata.siteUrl + image.url} />}
      {image && image.width && (
        <meta property="og:image:width" content={image.width} />
      )}
      {image && image.height && (
        <meta property="og:image:height" content={image.height} />
      )}
      {image && image.alt && (
        <meta property="og:image:alt" content={image.alt} />
      )}

      {/* Twitter Card tags */}
      {/* Twitter uses some og tags as fallback https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/markup */}
      <meta property="twitter:card" content="summary" />
      {image && image.alt && (
        <meta property="twitter:image:alt" content={image.alt} />
      )}

      {noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  )
}

MetadataContainer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    alt: PropTypes.string,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  siteMetadata: PropTypes.shape({
    siteTitle: PropTypes.string,
    siteUrl: PropTypes.string,
  }).isRequired,
  isHome: PropTypes.bool,
  noIndex: PropTypes.bool,
}

MetadataContainer.defaultProps = {
  description: "",
  image: null,
  isHome: false,
  noIndex: false,
}

const MetadataWithQuery = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            siteUrl
          }
        }
      }
    `
  )

  return (
    <Location>
      {({ location }) => (
        <MetadataContainer
          {...props}
          location={location}
          siteMetadata={site ? site.siteMetadata : {}}
        />
      )}
    </Location>
  )
}

export default MetadataWithQuery
