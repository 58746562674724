import React, { useContext } from "react"

const ModalContext = React.createContext({})

const ModalState = ({ children, dismissModal }) => (
  <ModalContext.Provider value={{ dismissModal }}>
    {children}
  </ModalContext.Provider>
)

const useModal = () => useContext(ModalContext)

export default ModalState

export { ModalContext, useModal }
