/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"
import Panel from "../panel/panel"
import Link from "../link/link"
import LinkedInIcon from "../../images/linkedin.png"
import TwitterIcon from "../../images/twitter.png"
import YouTubeIcon from "../../images/youtube_social_icon_white.png"
import ModalContactForm from "../modal-contact-form/modal-contact-form"

export const linkBucketStyles = {
  gridColumnStart: ["1", "unset"],
  gridColumnEnd: ["-1", "span 2"],
  maxWidth: ["27.5rem", "unset"],
  fontWeight: 300,
  fontSize: ["1.6rem", "1.2rem", "1.6rem"],
  lineHeight: [1.5, 1.25, 1.5],
  mb: ["6rem", "unset"],
  ul: {
    m: 0,
    p: 0,
    listStyleType: "none",
  },
  a: {
    textDecoration: "none",
    "&, &:visited": {
      color: "text",
      "&:hover": {
        color: "blue",
      },
    },
  },
  "a, li div": {
    display: "inline-block",
    py: ["0.5rem", "0.8rem", "0.5rem"],
  },
  "> span": {
    fontSize: ["1.3rem", "1.2rem", "1.3rem"],
    lineHeight: 1.25,
    textTransform: "uppercase",
    fontWeight: 700,
    mb: "2rem",
    display: "inline-block",
  },
}

const SiteFooter = () => (
  <Panel sx={{ py: ["3rem", "6rem", "8rem"] }}>
    <div
      sx={{
        gridColumnStart: ["1", "unset"],
        gridColumnEnd: ["-1", "span 4"],
        gridRowEnd: [null, "span 2", "unset"],
        maxWidth: ["27.5rem", "30rem", "unset"],
        mb: "3.5rem",
      }}
    >
      <StaticImage
        placeholder="none"
        src="../../images/moth-flame-logo-vr-learning.png"
        alt="Moth+Flame VR Learning"
      />
    </div>
    <div
      sx={{
        ...linkBucketStyles,
        gridColumnStart: ["1", "unset", "6"],
        gridRowEnd: [null, "span 2", "unset"],
        pt: [null, "3rem", "2rem"],
      }}
    >
      <span>Quick Links</span>
      <ul>
        <li>
          <Link to="/careers/">Careers</Link>
        </li>
        <li>
          <Link to="/#enterprise-ready">Our Solution</Link>
        </li>
        <li>
          <Link to="/learning-libraries">Learning Catalog</Link>
        </li>
        <li>
          <Link to="/learning-libraries/ready/">
            Government
          </Link>
        </li>
        <li>
          <Link to="/authoring/">
            Authoring
          </Link>
        </li>
        <li>
          <Link to="/pricing/">Pricing</Link>
        </li>
        <li>
          <Link to="/about/">Company</Link>
        </li>
        <li sx={{ "div:hover": { color: "blue" } }}>
          <ModalContactForm trigger="Contact Us" />
        </li>
        <li>
          <Link to="https://support.promisevr.com/">
            Customer Support
          </Link>
        </li>
      </ul>
    </div>
    <div
      sx={{
        ...linkBucketStyles,
        pt: [null, "3rem", "2rem"],
        mb: ["6rem", "6rem", "unset"],
      }}
    >
      <span>Connect with us</span>
      <ul>
        <li>
          <Link
            to="https://www.linkedin.com/company/moth-flame-vr/"
            target="_blank"
            sx={{
              backgroundImage: `url(${LinkedInIcon})`,
              backgroundSize: "1.8rem",
              backgroundRepeat: "no-repeat",
              backgroundPositionY: "0.7rem",
              pl: "2.8rem",
            }}
          >
            LinkedIn
          </Link>
        </li>
        <li>
          <Link
            to="https://twitter.com/mothandflamevr"
            target="_blank"
            sx={{
              backgroundImage: `url(${TwitterIcon})`,
              backgroundSize: "2rem",
              backgroundRepeat: "no-repeat",
              backgroundPositionY: "0.7rem",
              pl: "2.8rem",
            }}
          >
            Twitter
          </Link>
        </li>
        <li>
          <Link
            to="https://www.youtube.com/channel/UCObQyW0AlawhIKPrTe2tg8w"
            target="_blank"
            sx={{
              backgroundImage: `url(${YouTubeIcon})`,
              backgroundSize: "2rem",
              backgroundRepeat: "no-repeat",
              backgroundPositionY: "0.8rem",
              pl: "2.8rem",
            }}
          >
            YouTube
          </Link>
        </li>
      </ul>
    </div>
    <div
      sx={{
        ...linkBucketStyles,
        gridColumnStart: ["1", "7", "unset"],
        gridRowStart: [null, "2", "unset"],
        pt: [null, null, "2rem"],
      }}
    >
      <span>Contact info</span>
      <ul>
        <li>
          <span>
            224 W 35th St<br />
            Ste 500 PMB 322<br/>
            New York, NY 10001
          </span>
        </li>
        <li>
          <Themed.a href="mailto:contact@mothandflamevr.com">
            contact@mothandflamevr.com
          </Themed.a>
        </li>
      </ul>
    </div>
    <div
      sx={{
        gridColumnStart: ["1", "unset"],
        gridColumnEnd: ["-1", "span 4"],
        fontSize: "1.2rem",
        lineHeight: 1.25,
        fontWeight: 300,
        mt: [null, null, "8rem"],
      }}
    >
      Copyright © {new Date().getFullYear()}. All rights reserved.
    </div>
  </Panel>
)

export default SiteFooter
