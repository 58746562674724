import React from "react"
import { Themed } from "theme-ui"
import PropTypes from "prop-types"
import { useBreakpointIndex } from "@theme-ui/match-media"
import { Link as GatsbyLink } from "gatsby"
import { scrollToId } from "../../utils/scrolling"
import { headerHeight } from "../../utils/constants"

const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  onClick,
  ...other
}) => {
  const breakpointIndex = useBreakpointIndex({ defaultIndex: 1 })
  // any internal link will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)
  // any file will have a dot in the url
  const file = /\.[0-9a-z]+$/i.test(to)

  if (internal && !file) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        onClick={onClick}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <Themed.a
      href={to}
      rel={other.target === "_blank" ? "noopener noreferrer" : null}
      onClick={e => {
        // if we are going to a hash, account for fixed header
        if (to.indexOf("#") === 0) {
          e.preventDefault()
          const id = to.slice(1)
          scrollToId(id, breakpointIndex < 1 ? -headerHeight.mobile : 0)
          window.history.pushState({}, null, to)
        }
        if (onClick) onClick()
      }}
      {...other}
    >
      {children}
    </Themed.a>
  )
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
  onClick: PropTypes.func,
}

Link.defaultProps = {
  activeClassName: null,
  partiallyActive: true,
  children: null,
  onClick: null,
}

export default Link
