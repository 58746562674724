/** @jsx jsx */
import { jsx } from "theme-ui"
import { siteMaxWidth } from "../../gatsby-plugin-theme-ui"

export const gridStyles = {
  px: ["3rem", "6rem", "8.4rem"],
  mx: "auto",
  maxWidth: `${siteMaxWidth / 10}rem`, // 1600px
  display: "grid",
  gap: ["1rem", "2rem"],
  gridTemplateColumns: ["repeat(4, 1fr)", "repeat(8, 1fr)", "repeat(12, 1fr)"],
}

const GridWrap = props => (
  <div
    sx={{
      ...gridStyles,
    }}
    {...props}
  />
)

export default GridWrap
