/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import VisuallyHidden from "@reach/visually-hidden"
import { MdClose } from "react-icons/md"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import "@reach/dialog/styles.css"
import { disableScrolling } from "../../utils/scrolling"
import ModalState from "../../contexts/modal"
import { Button } from "../button/button"

const BUTTON_COMPONENTS = [Button.name]

const Modal = ({
  trigger,
  modalContent,
  triggerContainerStyles,
  initialFocusRef,
  allowOpen = () => true,
}) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const keyDownClose = ({ key }) => {
      if (open && key === "Escape") {
        setOpen(false)
      }
    }
    document.addEventListener("keydown", keyDownClose, { passive: true })

    // Disables scrolling when the modal is open, as suggested by
    // https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/dialog.html
    disableScrolling(open)

    return () => {
      document.removeEventListener("keydown", keyDownClose)
    }
  }, [open])

  const triggerIsButton =
    React.isValidElement(trigger) &&
    (trigger.type === "button" || BUTTON_COMPONENTS.includes(trigger.type.name))

  const triggerProps = {
    onClick: () => {
      if (allowOpen()) setOpen(true)
    },
    onKeyUp: ({ key }) => {
      if (key === "Enter") {
        setOpen(true)
      }
    },
  }

  return (
    <React.Fragment>
      {triggerIsButton ? (
        React.cloneElement(trigger, triggerProps)
      ) : (
        <div
          role="button"
          tabIndex={0}
          {...triggerProps}
          sx={{
            cursor: "pointer",
            ...triggerContainerStyles,
          }}
        >
          {trigger}
        </div>
      )}

      <DialogOverlay
        isOpen={open}
        onDismiss={() => setOpen(false)}
        initialFocusRef={initialFocusRef}
        sx={{
          bg: "rgba(40, 117, 234, 0.89)",
          zIndex: 1000,
        }}
      >
        <DialogContent
          aria-label="Modal window"
          sx={{
            border: "solid 1px #707070",
            borderRadius: "1rem",
            position: "relative",
            py: "4.5rem",
            px: ["2.4rem", "5rem", "7rem"],
            width: ["90vw", "50vw"],
          }}
        >
          <div
            role="button"
            tabIndex={0}
            onClick={() => setOpen(false)}
            onKeyUp={({ key }) => {
              if (key === "Enter") {
                setOpen(false)
              }
            }}
            sx={{
              position: "absolute",
              right: ["1.5rem", null, "3rem"],
              top: ["1.5rem", null, "3rem"],
              cursor: "pointer",
              svg: {
                fill: "lightGrey",
              },
              "&:hover, &:focus": {
                outline: 0,
                svg: {
                  fill: "text",
                },
              },
            }}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <span aria-hidden>
              <MdClose size="2.6rem" />
            </span>
          </div>
          <ModalState dismissModal={() => setOpen(false)}>
            {modalContent}
          </ModalState>
        </DialogContent>
      </DialogOverlay>
    </React.Fragment>
  )
}

Modal.propTypes = {
  trigger: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  modalContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Modal
